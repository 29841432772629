import { Routes } from '@angular/router';

export const businessContinuityPlanRoutes: Routes = [
    {
        path: '',
        loadComponent: () =>
        import('./components/business-continuity-plan-list/business-continuity-plan-list.component').then(
            (m) => m.BusinessContinuityPlanListComponent
        ),
    },
    {
        path: ':id',
        loadComponent: () =>
          import('./components/business-continuity-plan-details/business-continuity-plan-details-page/business-continuity-plan-details-page.component').then(
            (m) => m.BusinessContinuityPlanDetailsPageComponent
          ),
          children: [
            {
              path: 'overview',
              loadComponent: () =>
                import('./components/business-continuity-plan-details/overview/business-continuity-plan-details-overview/business-continuity-plan-details-overview.component'
                ).then((m) => m.BusinessContinuityPlanDetailsOverviewComponent),
            },
        ]
      },

];
