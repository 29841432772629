import { Routes } from '@angular/router';

export const auditsInsghtDraftReportRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./components/draft-report-list/draft-report-list.component').then(
        (m) => m.DraftReportListComponent
      ),
  },
  
];
