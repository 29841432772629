import { Routes } from '@angular/router';
import { WorkflowLevelsComponent } from './document/components/workflow-engine/workflow-levels/workflow-levels.component';
import { DocumentTypesComponent } from './document/components/workflow-engine/document-types/document-types.component';
import { RoleListComponent } from './general-settings/components/access-control/role-list/role-list.component';
import { RoleDetailsComponent } from './general-settings/components/access-control/role-details/role-details.component';

export const settingsRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./components/settings-page/settings-page.component').then(
        (m) => m.SettingsPageComponent
      ),
    children: [
      {
        path: 'general',
        children: [
          {
            path: 'system-preferences',
            loadComponent: () =>
              import(
                './general-settings/components/system-preferences/system-preferences.component'
              ).then((m) => m.SystemPreferencesComponent),
          },
          {
            path: 'organization-levels',
            loadComponent: () =>
              import(
                './general-settings/components/organization-levels/organization-levels.component'
              ).then((m) => m.OrganizationLevelsComponent),
          },
          {
            path: 'organization-units',
            loadComponent: () =>
              import(
                './general-settings/components/organization-units/organization-page/organization-page.component'
              ).then((m) => m.OrganizationPageComponent),
          },
        ],
      },
      {
        path: 'organization',
        children: [
          {
            path: 'masters',
            loadComponent: () =>
              import(
                './organization/components/masters/master-page/master-page.component'
              ).then((m) => m.MasterPageComponent),
          },
        ],
      },
      {
        path: 'compliance',
        children: [
          {
            path: 'masters',
            loadComponent: () =>
              import(
                './compliance/components/masters/master-page/master-page.component'
              ).then((m) => m.MasterPageComponent),
          },
        ],
      },
      {
        path: 'document',
        children: [
          {
            path: 'masters',
            loadComponent: () =>
              import(
                './document/components/masters/master-page/master-page.component'
              ).then((m) => m.MasterPageComponent),
          },
          {
            path: 'review-flow-settings',
            children: [
              {
                path: '',
                component: DocumentTypesComponent,
              },
              {
                path: ':id/workflow-levels',
                component: WorkflowLevelsComponent,
              },
            ],
          },
          {
            path: 'templates',
            loadComponent: () =>
              import(
                './document/components/document-template/document-template.component'
              ).then((m) => m.DocumentTemplateComponent),
          },
        ],
      },
      {
        path: 'incident',
        children: [
          {
            path: 'masters',
            loadComponent: () =>
              import(
                './incident/components/masters/master-page/master-page.component'
              ).then((m) => m.MasterPageComponent),
          },
        ],
      },

      {
        path: 'audit',
        children: [
          {
            path: 'audit-teams',
            loadComponent: () =>
              import(
                './audit/components/audit-teams/audit-teams.component'
              ).then((m) => m.AuditTeamsComponent),
          },
          {
            path: 'commencement-letter-template',
            loadComponent: () =>
              import(
                './audit/components/commencement-letter-template/commencement-letter-template.component'
              ).then((m) => m.CommencementLetterTemplateComponent),
          },
          {
            path: 'masters',
            loadComponent: () =>
              import(
                './audit/components/masters/master-page/master-page.component'
              ).then((m) => m.MasterPageComponent),
          },
        ],
      },
      {
        path: 'meeting',
        children: [
          {
            path: 'masters',
            loadComponent: () =>
              import(
                './review-meeting/components/masters/master-page/master-page.component'
              ).then((m) => m.MasterPageComponent),
          },
        ],
      },

      {
        path: 'masters',
        children: [
          {
            path: 'severity-levels',
            loadComponent: () =>
              import(
                './masters/components/severity-levels/severity-levels.component'
              ).then((m) => m.SeverityLevelsComponent),
          },
        ],
      },
      {
        path: 'access-control',
        children: [
          {
            path: '',
            component: RoleListComponent,
          },
          {
            path: ':id/permission',
            component: RoleDetailsComponent,
          },
        ],
      },     
      {
        path: 'brand-management',
        loadComponent: () =>
          import(
            './components/brand-management/brand-management.component'
          ).then((m) => m.BrandManagementComponent),
      },
      {
        path: 'compliance-data-management',
        loadComponent: () =>
          import(
            './general-settings/components/compliance-data-management/compliance-data-management.component'
          ).then((m) => m.ComplianceDataManagementComponent),
      },
      {
        path: 'organization-masters',
        loadComponent: () =>
          import(
            './components/organization-masters/organization-masters.component'
          ).then((m) => m.OrganizationMastersComponent),
      },
      {
        path: 'risk-matrix-configuration',
        loadComponent: () =>
          import(
            './risk-management/components/risk-matrix-configuration/risk-matrix-configuration-page/risk-matrix-configuration-page.component'
          ).then((m) => m.RiskMatrixConfigurationPageComponent),
      },
      {
        path: 'risk-impact-guidelines',
        loadComponent: () =>
          import(
            './risk-management/components/risk-impact-guidelines/risk-impact-guidelines.component'
          ).then((m) => m.RiskImpactGuidelinesComponent),
      },
      {
        path: 'risk-appetite-statements',
        loadComponent: () =>
          import(
            './risk-management/components/risk-appetite-statements/risk-appetite-statements.component'
          ).then((m) => m.RiskAppetiteStatementsComponent),
      },
      {
        path: 'risk-management-masters',
        loadComponent: () =>
          import(
            './risk-management/components/masters/master-page/master-page.component'
          ).then((m) => m.RiskManagementMastersComponent),
      },
      {
        path: 'email-transaction-logs',
        loadComponent: () =>
          import(
            './components/email-transaction-logs/email-transaction-logs.component'
          ).then((m) => m.EmailTransactionLogsComponent),
      },
      {
        path: 'audit-logs',
        loadComponent: () =>
          import('./components/audit-logs/audit-logs.component').then(
            (m) => m.AuditLogsComponent
          ),
      },
      {
        path: 'task-scheduler-logs',
        loadComponent: () =>
          import(
            './components/task-scheduler-logs/task-scheduler-logs.component'
          ).then((m) => m.TaskSchedulerLogsComponent),
      },
      {
        path: 'system-logs',
        loadComponent: () =>
          import('./components/system-logs/system-logs.component').then(
            (m) => m.SystemLogsComponent
          ),
      },
      {
        path: 'masters/review-frequencies',
        loadComponent: () =>
          import(
            './masters/components/review-frequencies/review-frequencies.component'
          ).then((m) => m.ReviewFrequenciesComponent),
      },
      {
        path: 'masters/tool-types',
        loadComponent: () =>
          import('./masters/components/tool-types/tool-types.component'
          ).then((m) => m.ToolTypesComponent),
      },
      {
        path: 'masters/action-plan-statuses',
        loadComponent: () =>
          import(
            './masters/components/action-plan-statuses/action-plan-statuses.component'
          ).then((m) => m.ActionPlanStatusesComponent),
      },
      {
        path: 'masters/life-cycle-statuses',
        loadComponent: () =>
          import(
            './masters/components/life-cycle-statuses/life-cycle-statuses.component'
          ).then((m) => m.LifeCycleStatusesComponent),
      },
      {
        path: 'masters/checklists',
        loadComponent: () =>
          import('./masters/components/checklists/checklists.component').then(
            (m) => m.ChecklistsComponent
          ),
      },
      {
        path: 'masters/expense-category',
        loadComponent: () =>
          import('./masters/components/expense-category/expense-category.component').then(
            (m) => m.ExpenseCategoryComponent
          ),
      },
      {
        path: 'masters/question-groups',
        loadComponent: () =>
          import(
            './masters/components/question-groups/question-groups.component'
          ).then((m) => m.QuestionGroupsComponent),
      },
      {
        path: 'masters/question-library',
        loadComponent: () =>
          import(
            './masters/components/question-library/question-library.component'
          ).then((m) => m.QuestionLibraryComponent),
      },
      {
        path: 'masters/unit',
        loadComponent: () =>
          import('./masters/components/unit/unit.component').then(
            (m) => m.UnitComponent
          ),
      },
      {
        path: 'masters/sources',
        loadComponent: () =>
          import('./masters/components/source/source.component').then(
            (m) => m.SourceComponent
          ),
      },
    ],
  },
  {
    path: '',
    redirectTo: '/auth/login',
    pathMatch: 'full',
  },
];
