<div class="row">

  <div class="col-md-4">

  

    <div class="widget-block widget-grey-bg widget-ligh-green-bg">
        <div class="row">
            <div class="col-md-12">
                <h4>Business Continuity Metrix</h4>

            </div>

            <div class="col-md-12">

              <div class="table-responsive">

                <table class="table table-bordered theme-green-table mb-0 mt-0">
        <thead>
          <tr>
          <th style="width: 50%;">Parameter</th>
          <th style="width: 50%;">Target</th>
          </tr>
        </thead>
        <tbody>
        
          <tr>
            <td>Recovery Time Objective (RTO) </td>
            <td>4 Hours</td>
          </tr>
          <tr>
            <td>Maximum Tollerable Period of Disruption (MTPD)</td>
            <td>12 Hours </td>
          </tr>
          <tr>
            <td>Recovery point Objective(RPO) </td>
            <td>5 </td>
          </tr>
        
        
        </tbody>
        
                </table>
        
        
              </div>
            </div>
        </div>
    

    </div>

</div>

</div>

<!-- Report Started -->

<div class="title-bar mb-3">
  <div class="row">
    <div class="col-md-5 d-flex align-items-center">
      <h2>Reports</h2>
    </div>
    <div class="col-md-7 d-flex justify-content-end">
      <!-- Search Box -->
      <div class="me-2">
        <input type="text" id="searchInput" name="searchInput" class="form-control search-box" placeholder="Search here...">
      </div>

   

      <!-- More Dropdown -->
      <div class="dropdown more-dropdown btn-grey me-2">
        <button type="button" data-bs-toggle="dropdown" class="btn btn-secondary dropdown-toggle btn-grey"> More </button>
        <ul class="dropdown-menu">
          <li><a class="dropdown-item"><img src="assets/images/icons/icon-upload.svg" alt="Export Icon" class="me-1"> Export </a></li>
          <li><a class="dropdown-item"><img src="assets/images/icons/icon-fresh.svg" alt="Refresh Icon" class="me-1"> Refresh </a></li>
        </ul>
      </div>

      <!-- Add New Button -->
      <button type="button" class="btn btn-primary btn-theme">
        <img src="assets/images/icons/icon-plus.svg" alt="Add Icon"> Add
      </button>
    </div>
  </div>
</div>


<div class="container">
<div class="row">
  <div class="col-md-4">
  <div class="report-box-list">

    <div class="icon-box">

      <img src="assets/images/report-icons/icon-compliance.svg" class="d-block mx-auto cursor-pointer" alt="upload icon" />


    </div>

    <h4>
      Compliance
    </h4>

    <ul>
<li>
  <a href="#">Compliance Audit Trail</a>
</li>
<li>
  <a href="#">Policy Acknowledgment Status</a>
</li>
<li>
  <a href="#">Training Completion Report
  </a>
</li>
<li>
  <a href="#">License Expiration Tracking</a>
</li>
<li>
  <a href="#">Regulatory Change Impact Analysis
  </a>
</li>

    </ul>

  </div>
  </div>
  <div class="col-md-4">
    <div class="report-box-list">
  
      <div class="icon-box">
  
        <img src="assets/images/report-icons/icon-risk.svg" class="d-block mx-auto cursor-pointer" alt="upload icon" />
  
  
      </div>
  
      <h4>
      Risk
      </h4>
  
      <ul>
  <li>
    <a href="#">Risk count by Risk Rating</a>
  </li>
  <li>
    <a href="#">Risk Assessment Summary</a>
  </li>
  <li>
    <a href="#">Control Effectiveness Overview

    </a>
  </li>
  <li>
    <a href="#">Risk Heatmap Report</a>
  </li>
  <li>
    <a href="#">Open Risks by Department
    </a>
  </li>
  <li>
    <a href="#">Risk Mitigation Progress
    </a>
  </li>
  
      </ul>
  
    </div>
    </div>

    <div class="col-md-4">
      <div class="report-box-list">
    
        <div class="icon-box">
    
          <img src="assets/images/report-icons/icon-incident.svg" class="d-block mx-auto cursor-pointer" alt="upload icon" />
    
    
        </div>
    
        <h4>
          Incident
        </h4>
    
        <ul>
    <li>
      <a href="#">Incident Response Times
      </a>
    </li>
    <li>
      <a href="#">Open vs. Closed Incidents</a>
    </li>
    <li>
      <a href="#">Incident by Category
      </a>
    </li>
    <li>
      <a href="#">Incidents by Impact Level</a>
    </li>
    <li>
      <a href="#">Incident Trends Over Time
      </a>
    </li>
    
        </ul>
    
      </div>
      </div>
      <div class="col-md-4">
        <div class="report-box-list">
      
          <div class="icon-box">
      
            <img src="assets/images/report-icons/icon-organization.svg" class="d-block mx-auto cursor-pointer" alt="upload icon" />
      
      
          </div>
      
          <h4>
            Organization
          </h4>
      
          <ul>
      <li>
        <a href="#">Organizational Compliance Posture</a>
      </li>
      <li>
        <a href="#">Entity-Level Controls Assessment
        </a>
      </li>
      <li>
        <a href="#">Department Compliance Scorecard
        </a>
      </li>
      <li>
        <a href="#">Role-Based Access Review</a>
      </li>
      <li>
        <a href="#">Third-Party Vendor Risk Report
        </a>
      </li>
      
          </ul>
      
        </div>
        </div>
        <div class="col-md-4">
          <div class="report-box-list">
        
            <div class="icon-box">
        
              <img src="assets/images/report-icons/icon-document-management.svg" class="d-block mx-auto cursor-pointer" alt="upload icon" />
        
        
            </div>
        
            <h4>
              Document Management
            </h4>
        
            <ul>
        <li>
          <a href="#">Document Revision History</a>
        </li>
        <li>
          <a href="#">Policy Distribution and Read Confirmation</a>
        </li>
        <li>
          <a href="#">Records Retention Schedule Adherence
          </a>
        </li>
        <li>
          <a href="#">Upcoming Document Review Dates
          </a>
        </li>
        <li>
          <a href="#">Overdue Documents Report
          </a>
        </li>
        
            </ul>
        
          </div>
          </div>
          <div class="col-md-4">
            <div class="report-box-list">
          
              <div class="icon-box">
          
                <img src="assets/images/report-icons/icon-meetings.svg" class="d-block mx-auto cursor-pointer" alt="upload icon" />
          
          
              </div>
          
              <h4>
                Meeting Management
              </h4>
          
              <ul>
          <li>
            <a href="#">Compliance Audit Trail</a>
          </li>
          <li>
            <a href="#">Policy Acknowledgment Status</a>
          </li>
          <li>
            <a href="#">Training Completion Report
            </a>
          </li>
          <li>
            <a href="#">License Expiration Tracking</a>
          </li>
          <li>
            <a href="#">Regulatory Change Impact Analysis
            </a>
          </li>
          
              </ul>
          
            </div>
            </div>
</div>
</div>


<!-- Report End -->


<!-- control-list-start -->
<div class="row">
  <div class="col-md-9">
    <div class="accordion theme-accordion" id="accordionExamplenew">
      <div class="accordion-item check-list-item">
        <h2 class="accordion-header" id="headingOne">
          <button
            class="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#risk1new"
            aria-expanded="true"
            aria-controls="collapseOne"
          >
          Module Permissions
          </button>

       
        </h2>
        
        <div
          id="risk1new"
          class="accordion-collapse collapse show"
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExamplenew"
        >
          <div class="accordion-body">
            <div class="row">
              <div class="col-md-12">
                  <div class="table-responsive">
                      <table class="table table-bordered theme-table mb-0 mt-0">
                          <thead>
                              <tr>
                                 
                                  <th scope="col" style="min-width: 300px">
                                    Module
                                      <a class="table-filter"><img src="assets/images/icons/column-filter.svg" /></a>
                                  </th>
                                  <th scope="col">
                                    Sub Module
                                      <a class="table-filter"><img src="assets/images/icons/column-filter.svg" /></a>
                                  </th>
      
                                  <th style="width: 100px">
                                    Manage
                                      <a class="table-filter"><img src="assets/images/icons/column-filter.svg" /></a>
                                  </th>
                                  <th style="width: 100px">
                                    Modify
                                      <a class="table-filter"><img src="assets/images/icons/column-filter.svg" /></a>
                                  </th>
                                  <th style="width: 100px">
                                    View
                                      <a class="table-filter"><img src="assets/images/icons/column-filter.svg" /></a>
                                  </th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr>
                                 
                                  <td class="align-top" rowspan="4">Compliance Management</td>
                                  <td>Complainants Register</td>
                                  <td>
                                    <div class="toggle-btn"><input  type="checkbox"></div>
                                  </td>
                                  <td>
                                    <div class="toggle-btn"><input  type="checkbox"></div>
                                  </td>
                                  <td>
                                    <div class="toggle-btn"><input  type="checkbox"></div>
                                  </td>
                              </tr>
                              <tr>
                                 
                                <td>SLA & Contract</td>
                                <td>
                                  <div class="toggle-btn"><input  type="checkbox"></div>
                                </td>
                                <td>
                                  <div class="toggle-btn"><input  type="checkbox"></div>
                                </td>
                                <td>
                                  <div class="toggle-btn"><input  type="checkbox"></div>
                                </td>
                            </tr>
                            <tr>
                                 
                              <td>Process</td>
                              <td>
                                <div class="toggle-btn"><input  type="checkbox"></div>
                              </td>
                              <td>
                                <div class="toggle-btn"><input  type="checkbox"></div>
                              </td>
                              <td>
                                <div class="toggle-btn"><input  type="checkbox"></div>
                              </td>
                          </tr>
                          <tr>
                                 
                            <td>Control</td>
                            <td>
                              <div class="toggle-btn"><input  type="checkbox"></div>
                            </td>
                            <td>
                              <div class="toggle-btn"><input  type="checkbox"></div>
                            </td>
                            <td>
                              <div class="toggle-btn"><input  type="checkbox"></div>
                            </td>
                        </tr>
                        <tr>
                                 
                          <td class="align-top" rowspan="7">Risk Management</td>
                          <td>Risk Matrix</td>
                          <td>
                            <div class="toggle-btn"><input  type="checkbox"></div>
                          </td>
                          <td>
                            <div class="toggle-btn"><input  type="checkbox"></div>
                          </td>
                          <td>
                            <div class="toggle-btn"><input  type="checkbox"></div>
                          </td>
                      </tr>
                      <tr>
                         
                        <td>Risk Appetites Stataement</td>
                        <td>
                          <div class="toggle-btn"><input  type="checkbox"></div>
                        </td>
                        <td>
                          <div class="toggle-btn"><input  type="checkbox"></div>
                        </td>
                        <td>
                          <div class="toggle-btn"><input  type="checkbox"></div>
                        </td>
                    </tr>
                    <tr>
                         
                      <td>KRI’S</td>
                      <td>
                        <div class="toggle-btn"><input  type="checkbox"></div>
                      </td>
                      <td>
                        <div class="toggle-btn"><input  type="checkbox"></div>
                      </td>
                      <td>
                        <div class="toggle-btn"><input  type="checkbox"></div>
                      </td>
                  </tr>
                  <tr>
                         
                    <td>Risk Register</td>
                    <td>
                      <div class="toggle-btn"><input  type="checkbox"></div>
                    </td>
                    <td>
                      <div class="toggle-btn"><input  type="checkbox"></div>
                    </td>
                    <td>
                      <div class="toggle-btn"><input  type="checkbox"></div>
                    </td>
                </tr>
                <tr>
                         
                  <td>Action Plan</td>
                  <td>
                    <div class="toggle-btn"><input  type="checkbox"></div>
                  </td>
                  <td>
                    <div class="toggle-btn"><input  type="checkbox"></div>
                  </td>
                  <td>
                    <div class="toggle-btn"><input  type="checkbox"></div>
                  </td>
              </tr>
              <tr>
                         
                <td>Risk Heatmap</td>
                <td>
                  <div class="toggle-btn"><input  type="checkbox"></div>
                </td>
                <td>
                  <div class="toggle-btn"><input  type="checkbox"></div>
                </td>
                <td>
                  <div class="toggle-btn"><input  type="checkbox"></div>
                </td>
            </tr> 

            <tr>
                         
              <td>Reports</td>
              <td>
                <div class="toggle-btn"><input  type="checkbox"></div>
              </td>
              <td>
                <div class="toggle-btn"><input  type="checkbox"></div>
              </td>
              <td>
                <div class="toggle-btn"><input  type="checkbox" checked></div>
              </td>
          </tr> 
                         
                            
                          
                          </tbody>
                      </table>
                  </div>
              </div>
              <div class="col-md-12">
                <div class="d-flex justify-content-end mt-4">
                  <button type="button" class="btn btn-secondary btn-theme-outline me-2" data-bs-dismiss="modal"> 
                    Cancel
                  </button>
                  <button type="submit" class="btn btn-primary btn-theme">
                    Update
                  </button>
                </div>
              </div>
          </div>
           
          </div>
          </div>
          
      
    
    
     
    </div>
    <div class="accordion-item check-list-item">
      <h2 class="accordion-header" id="headingOneee">
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#risk1eenew"
          aria-expanded="true"
          aria-controls="collapseOne"
        >
        Department Level Access
        </button>

     
      </h2>
      <div
        id="risk1eenew"
        class="accordion-collapse collapse"
        aria-labelledby="headingOne"
        data-bs-parent="#accordionExample"
      >
      <div class="accordion-body">
        <div class="row">
          <div class="col-md-12">
              <div class="table-responsive">
                  <table class="table table-bordered theme-table mb-0 mt-0">
                      <thead>
                          <tr>
                             
                              <th scope="col" style="min-width: 300px">
                                Module
                                  <a class="table-filter"><img src="assets/images/icons/column-filter.svg" /></a>
                              </th>
                              <th scope="col">
                                Sub Module
                                  <a class="table-filter"><img src="assets/images/icons/column-filter.svg" /></a>
                              </th>
  
                              <th style="width: 100px">
                                Manage
                                  <a class="table-filter"><img src="assets/images/icons/column-filter.svg" /></a>
                              </th>
                              <th style="width: 100px">
                                Modify
                                  <a class="table-filter"><img src="assets/images/icons/column-filter.svg" /></a>
                              </th>
                              <th style="width: 100px">
                                View
                                  <a class="table-filter"><img src="assets/images/icons/column-filter.svg" /></a>
                              </th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                             
                              <td class="align-top" rowspan="4">Compliance Management</td>
                              <td>Complainants Register</td>
                              <td>
                                <div class="toggle-btn"><input  type="checkbox"></div>
                              </td>
                              <td>
                                <div class="toggle-btn"><input  type="checkbox"></div>
                              </td>
                              <td>
                                <div class="toggle-btn"><input  type="checkbox"></div>
                              </td>
                          </tr>
                          <tr>
                             
                            <td>SLA & Contract</td>
                            <td>
                              <div class="toggle-btn"><input  type="checkbox"></div>
                            </td>
                            <td>
                              <div class="toggle-btn"><input  type="checkbox"></div>
                            </td>
                            <td>
                              <div class="toggle-btn"><input  type="checkbox"></div>
                            </td>
                        </tr>
                        <tr>
                             
                          <td>Process</td>
                          <td>
                            <div class="toggle-btn"><input  type="checkbox"></div>
                          </td>
                          <td>
                            <div class="toggle-btn"><input  type="checkbox"></div>
                          </td>
                          <td>
                            <div class="toggle-btn"><input  type="checkbox"></div>
                          </td>
                      </tr>
                      <tr>
                             
                        <td>Control</td>
                        <td>
                          <div class="toggle-btn"><input  type="checkbox"></div>
                        </td>
                        <td>
                          <div class="toggle-btn"><input  type="checkbox"></div>
                        </td>
                        <td>
                          <div class="toggle-btn"><input  type="checkbox"></div>
                        </td>
                    </tr>
                    <tr>
                             
                      <td class="align-top" rowspan="7">Risk Management</td>
                      <td>Risk Matrix</td>
                      <td>
                        <div class="toggle-btn"><input  type="checkbox"></div>
                      </td>
                      <td>
                        <div class="toggle-btn"><input  type="checkbox"></div>
                      </td>
                      <td>
                        <div class="toggle-btn"><input  type="checkbox"></div>
                      </td>
                  </tr>
                  <tr>
                     
                    <td>Risk Appetites Stataement</td>
                    <td>
                      <div class="toggle-btn"><input  type="checkbox"></div>
                    </td>
                    <td>
                      <div class="toggle-btn"><input  type="checkbox"></div>
                    </td>
                    <td>
                      <div class="toggle-btn"><input  type="checkbox"></div>
                    </td>
                </tr>
                <tr>
                     
                  <td>KRI’S</td>
                  <td>
                    <div class="toggle-btn"><input  type="checkbox"></div>
                  </td>
                  <td>
                    <div class="toggle-btn"><input  type="checkbox"></div>
                  </td>
                  <td>
                    <div class="toggle-btn"><input  type="checkbox"></div>
                  </td>
              </tr>
              <tr>
                     
                <td>Risk Register</td>
                <td>
                  <div class="toggle-btn"><input  type="checkbox"></div>
                </td>
                <td>
                  <div class="toggle-btn"><input  type="checkbox"></div>
                </td>
                <td>
                  <div class="toggle-btn"><input  type="checkbox"></div>
                </td>
            </tr>
            <tr>
                     
              <td>Action Plan</td>
              <td>
                <div class="toggle-btn"><input  type="checkbox"></div>
              </td>
              <td>
                <div class="toggle-btn"><input  type="checkbox"></div>
              </td>
              <td>
                <div class="toggle-btn"><input  type="checkbox"></div>
              </td>
          </tr>
          <tr>
                     
            <td>Risk Heatmap</td>
            <td>
              <div class="toggle-btn"><input  type="checkbox"></div>
            </td>
            <td>
              <div class="toggle-btn"><input  type="checkbox"></div>
            </td>
            <td>
              <div class="toggle-btn"><input  type="checkbox"></div>
            </td>
        </tr> 

        <tr>
                     
          <td>Reports</td>
          <td>
            <div class="toggle-btn"><input  type="checkbox"></div>
          </td>
          <td>
            <div class="toggle-btn"><input  type="checkbox"></div>
          </td>
          <td>
            <div class="toggle-btn"><input  type="checkbox" checked></div>
          </td>
      </tr> 
                     
                        
                      
                      </tbody>
                  </table>
              </div>
          </div>
          <div class="col-md-12">
            <div class="d-flex justify-content-end mt-4">
              <button type="button" class="btn btn-secondary btn-theme-outline me-2" data-bs-dismiss="modal"> 
                Cancel
              </button>
              <button type="submit" class="btn btn-primary btn-theme">
                Update
              </button>
            </div>
          </div>
      </div>
       
      </div>
        </div>
        
    
  
  
   
  </div>
    </div>
    
  </div>
  
</div>


<!-- Controll list end -->













<!-- Draft Report Start-->

<div class="row mt-4">
    <div class="col-md-9">
        <div class="widget-block bg-light-grey">

            <div class="row">
                <div class="col-md-3">

                    <div class="page-title-box">
                        <h4>Report Content</h4>

                        <ul class="affix"  data-spy="affix" data-offset-top="197">
                            <li>
                                <a href="#">Oveview</a>
                            </li>
                            <li>
                                <a href="#">Findings</a>

                            </li>
                            <li class="ms-3">
                                <a href="#">Major Non-Conformance </a>

                            </li>
                            <li class="ms-3">
                                <a href="#">Potential Improvement in Multi-Factor Authentication </a>

                            </li>
                            <li>
                                <a href="#">Conclusion </a>

                            </li>

                            
                           
                        </ul>



                    </div>

                </div>

                <div class="col-md-9 draft-report">

<div class="draft-cover-box">




<div class="row">
    <div class="col-md-12"><img src="assets/images/logo-white-yellow.svg"></div>
    <div class="col-md-12">    <h2>The Quality Management System<br> documentation is not adequately controlled</h2>
    </div>
    
</div>

<div class="row">
    <div class="col-md-3 col-sm-6"><label class="label-text">Reference Number: </label><p class="p-text"> SLA 0001 </p></div>
    <div class="col-md-3 col-sm-6"><label class="label-text">Prepared by: </label><p class="p-text"> John Smith</p></div>
    <div class="col-md-5 col-sm-6"><label class="label-text">Prepared at : </label><p class="p-text"> Prepared at April 14, 2024, 3:15 PM </p></div>
</div>

    


</div>

<div class="draft-page">
<div class="row">
    <div class="col-md-12">
        <h4>Audit Report Overview</h4>
        <h6 class="mt-3">Executive Summary</h6>
        <p>This internal audit was conducted to evaluate the compliance and effectiveness of [Department/Process Name] with relevant policies, procedures, and regulations. The audit identified five findings, classified into Major NC, Minor NC, Opportunity for Improvement, and Observation. </p>
    
    </div>
</div>
<div class="row">
    <div class="col-md-6">

        <div class="sm-widget-block align-items-start">
          <img src="assets/images/audit-icons/icon-auditor.svg">
            <div class="ms-2">
                <label class="label-text">Auditor </label><p class="p-text">Ahmed Khan   </p>
            </div>

            


        </div>
        


    </div>
    <div class="col-md-6">

        <div class="sm-widget-block align-items-start">
          <img src="assets/images/audit-icons/icon-audit-lead.svg">
            <div class="ms-2">
                <label class="label-text">Lead Auditor </label><p class="p-text">Ahmed Khan   </p>
            </div>

            


        </div>
        


    </div>
    <div class="col-md-6">

        <div class="sm-widget-block align-items-start">
          <img src="assets/images/audit-icons/auditee-icon.svg">
            <div class="ms-2">
                <label class="label-text">Auditee </label><p class="p-text">Ahmed Khan   </p>
            </div>

            


        </div>
        


    </div>
    <div class="col-md-6">

        <div class="sm-widget-block align-items-start">
          <img src="assets/images/audit-icons/icon-audit-date.svg">
            <div class="ms-2">
                <label class="label-text">Report Date </label><p class="p-text">April 14, 2024, 3:15 PM   </p>
            </div>

            


        </div>
        


    </div>
</div>
<div class="row">
    <h4>Findings</h4>
    <div class="col-md-12">
      <div class="accordion theme-accordion" id="accordionExample">
        <div class="accordion-item check-list-item">
          <h2 class="accordion-header" id="headingOne">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#risk1"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
            Inadequate Control of QMS Documentation 
            </button>

         
          </h2>
          <a class="edit-btn-checklist"><i class="fa fa-pencil"></i> Edit </a>
          <div
            id="risk1"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">






<div class="row">
    <div class="col-md-12">
        <div class="alert-danger d-flex mb-3">
          <img class="me-1" src="assets/images/audit-icons/icon-flag.svg" alt="Overview Icon">Challenged by Ibrahim Ahmed on April 14, 2024 <a class="a-link text-danger" href="#">12 responses (2 New)</a>

        </div>
      
      </div>
</div>
<div class="row">
    <div class="col-md-12">

        <div class="sm-widget-block align-items-start">
          <img src="assets/images/audit-icons/icon-auditable-item.svg">
            <div class="ms-2">
                <label class="label-text">Auditable Item  </label><p class="p-text">Quality Management System (QMS) Documentation    </p>
            </div>

            


        </div>
        


    </div>
    <div class="col-md-6">

        <div class="sm-widget-block align-items-start">
          <img src="assets/images/audit-icons/icon-department.svg">
            <div class="ms-2">
                <label class="label-text">Department </label><p class="p-text">Finance   </p>
            </div>

            


        </div>
        


    </div>
    <div class="col-md-6">

        <div class="sm-widget-block align-items-start">
          <img src="assets/images/audit-icons/icon-severity.svg">
            <div class="ms-2">
                <label class="label-text">Severity </label><p class="p-text"><label class="status-red">High</label>  </p>
            </div>

            


        </div>
        


    </div>
    
</div>

<div class="row">
    <div class="col-md-12">
        <h4>Description</h4>

        <p class="mb-1">The Quality Management System documentation is not adequately controlled. Several documents were found to be obsolete and had not been reviewed or updated for over a year, leading to potential miscommunication and non-compliance with current standards. 



        </p>
        <a class="a-link">Read more</a>
    </div>

    <div class="col-md-12 mt-3">
        <h4>Recommendations</h4>

        <p class="mb-1">The Quality Management System documentation is not adequately controlled. Several documents were found to be obsolete and had not been reviewed or updated for over a year, leading to potential miscommunication and non-compliance with current standards. 



        </p>
        <a class="a-link">Read more</a>
    </div>

    <div class="col-md-12 mt-3">
        <h4 class="mb-3">
            Evidence
        </h4>

        <div class="row">
            <div class="col-md-6">
                <div class="uploaded-file justify-content-between align-items-center mb-2 mt-1"><div class="d-flex"><img src="assets/images/file-icons/icon-pdf.svg" width="30" class="me-3"><div> COMP/2023/012.pdf001.pdf <br><small>105KB</small></div></div><div class="delet-icon cursor-pointer"><img src="assets/images/icons/icon-download-2.svg"></div></div>
            </div>
            <div class="col-md-6">
                <div class="uploaded-file justify-content-between align-items-center mb-2 mt-1"><div class="d-flex"><img src="assets/images/file-icons/icon-pdf.svg" width="30" class="me-3"><div> COMP/2023/012.pdf001.pdf <br><small>105KB</small></div></div><div class="delet-icon cursor-pointer"><img src="assets/images/icons/icon-download-2.svg"></div></div>
            </div>
            <div class="col-md-12">
                <a class="a-link" href="#">3+ more</a>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-md-12 d-flex align-items-center justify-content-between">
                <h4>Latest Responses</h4> <a href="#" class="a-link">View All Responses</a>

            </div>
            <div class="col-md-12">

                <div class="sm-widget-block ">
                    

                    <div>

                        <div class="d-flex ">
                            <h5 class="me-2 font-md d-flex align-items-center">Smith </h5><small>Auditee</small>
                        </div>

                       
                    <p class="mb-1">The Quality Management System documentation is not adequately controlled. Several documents were found to be obsolete and had not been reviewed or updated for over a year, leading to potential miscommunication and non-compliance with current standards. 



                    </p>
                    <a class="a-link">Read more</a>

                    
        <div class="row mt-2">
            <div class="col-md-6">
                <div class="uploaded-file justify-content-between align-items-center mb-2 mt-1 bg-white"><div class="d-flex"><img src="assets/images/file-icons/icon-pdf.svg" width="30" class="me-3"><div> COMP/2023/012.pdf001.pdf <br><small>105KB</small></div></div></div>
            </div>
            <div class="col-md-6">
                <div class="uploaded-file justify-content-between align-items-center mb-2 mt-1 bg-white"><div class="d-flex"><img src="assets/images/file-icons/icon-pdf.svg" width="30" class="me-3"><div> COMP/2023/012.pdf001.pdf <br><small>105KB</small></div></div></div>

            </div>
          
        </div>

                   
                </div>
                    
        
        
                </div>
                
        
        
            </div>
            
                <div class="col-md-12">
                    <div class="widget-block widget-grey-bg text-center d-flex align-items-center justify-content-center ">
                
                        <div>
                
                        <h4 class="text-center">No Department Response</h4>
                        <p>Check back soon for updates</p>
                        <button  type="button" class="btn btn-primary btn-theme">Add Response</button>
                
                    </div>
                
                    </div>
            
            </div>
        </div>
    </div>
</div>








                </div>
            </div>
            
        
      
      
       
      </div>
      <div class="accordion-item check-list-item">
        <h2 class="accordion-header" id="headingOneee">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#risk1ee"
            aria-expanded="true"
            aria-controls="collapseOne"
          >
          Inadequate Control of QMS Documentation 
          </button>

       
        </h2>
        <a class="edit-btn-checklist"><i class="fa fa-pencil"></i> Edit </a>
        <div
          id="risk1ee"
          class="accordion-collapse collapse"
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample"
        >
        <div class="accordion-body">






            <div class="row">
                <div class="col-md-12">
                    <div class="alert-success d-flex mb-3">
                      <img  src="assets/images/audit-icons/icon-tick-success-circle.svg" alt="Overview Icon">Finding accepted by Ibrahim Ahmed on April 14, 2024
            
                    </div>
                  
                  </div>
            </div>
            <div class="row">
                <div class="col-md-12">
            
                    <div class="sm-widget-block align-items-start">
                      <img src="assets/images/audit-icons/icon-auditable-item.svg">
                        <div class="ms-2">
                            <label class="label-text">Auditable Item  </label><p class="p-text">Quality Management System (QMS) Documentation    </p>
                        </div>
            
                        
            
            
                    </div>
                    
            
            
                </div>
                <div class="col-md-6">
            
                    <div class="sm-widget-block align-items-start">
                      <img src="assets/images/audit-icons/icon-department.svg">
                        <div class="ms-2">
                            <label class="label-text">Department </label><p class="p-text">Finance   </p>
                        </div>
            
                        
            
            
                    </div>
                    
            
            
                </div>
                <div class="col-md-6">
            
                    <div class="sm-widget-block align-items-start">
                      <img src="assets/images/audit-icons/icon-severity.svg">
                        <div class="ms-2">
                            <label class="label-text">Severity </label><p class="p-text"><label class="status-red">High</label>  </p>
                        </div>
            
                        
            
            
                    </div>
                    
            
            
                </div>
                
            </div>
            
            <div class="row">
                <div class="col-md-12">
                    <h4>Description</h4>
            
                    <p class="mb-1">The Quality Management System documentation is not adequately controlled. Several documents were found to be obsolete and had not been reviewed or updated for over a year, leading to potential miscommunication and non-compliance with current standards. 
            
            
            
                    </p>
                    <a class="a-link">Read more</a>
                </div>
            
                <div class="col-md-12 mt-3">
                    <h4>Recommendations</h4>
            
                    <p class="mb-1">The Quality Management System documentation is not adequately controlled. Several documents were found to be obsolete and had not been reviewed or updated for over a year, leading to potential miscommunication and non-compliance with current standards. 
            
            
            
                    </p>
                    <a class="a-link">Read more</a>
                </div>
            
                <div class="col-md-12 mt-3">
                    <h4 class="mb-3">
                        Evidence
                    </h4>
            
                    <div class="row">
                        <div class="col-md-6">
                            <div class="uploaded-file justify-content-between align-items-center mb-2 mt-1"><div class="d-flex"><img src="assets/images/file-icons/icon-pdf.svg" width="30" class="me-3"><div> COMP/2023/012.pdf001.pdf <br><small>105KB</small></div></div><div class="delet-icon cursor-pointer"><img src="assets/images/icons/icon-download-2.svg"></div></div>
                        </div>
                        <div class="col-md-6">
                            <div class="uploaded-file justify-content-between align-items-center mb-2 mt-1"><div class="d-flex"><img src="assets/images/file-icons/icon-pdf.svg" width="30" class="me-3"><div> COMP/2023/012.pdf001.pdf <br><small>105KB</small></div></div><div class="delet-icon cursor-pointer"><img src="assets/images/icons/icon-download-2.svg"></div></div>
                        </div>
                        <div class="col-md-12">
                            <a class="a-link" href="#">3+ more</a>
                        </div>
                    </div>
            
                    <div class="row mt-3">
                        <div class="col-md-12 d-flex align-items-center justify-content-between">
                            <h4>Latest Responses</h4> <a href="#" class="a-link">View All Responses</a>
            
                        </div>
                        
                        
                            <div class="col-md-12">
                                <div class="widget-block widget-grey-bg text-center d-flex align-items-center justify-content-center ">
                            
                                    <div>
                            
                                    <h4 class="text-center">No Department Response</h4>
                                    <p>Check back soon for updates</p>
                                    <button  type="button" class="btn btn-primary btn-theme">Add Response</button>
                            
                                </div>
                            
                                </div>
                        
                        </div>
                    </div>
                </div>
            </div>
            
            
            
            
            
            
            
            
                           
                        </div>
          </div>
          
      
    
    
     
    </div>
      </div>
      
    </div>
     <div class="col-md-12">
       <h4> Action Plan <a class="btn-add-more-plus">Add</a> </h4> 
        </div> 

        <div class="col-md-12">
              <div class="accordion theme-accordion" id="accordionExample11">
                                <div class="accordion-item check-list-item">
                                  <h2 class="accordion-header" id="headingOne11">
                                    <button
                                      class="accordion-button"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#risk11act"
                                      aria-expanded="true"
                                      aria-controls="collapseOne"
                                    >
                                    Potential Improvement in Multi-Factor Authentication 
                                    </button>
                
                                 
                                  </h2>
                                  <div
                                    id="risk11act"
                                    class="accordion-collapse collapse show"
                                    aria-labelledby="headingOne11"
                                    data-bs-parent="#accordionExample11"
                                  >
                                    <div class="accordion-body">
                                      <div class="row">
                                       
                        
                                        <div class="col-md-6 col-sm-12">
                                          <label class="label-text">Target date </label>
                                          <p class="p-text">
                                            April 14, 2024, 3:15 PM
                                          </p>
                                         
                                        </div>
                                        <div class="col-md-6 col-sm-12">
                                            <label class="label-text">Responsibility </label>
                                            <p class="p-text">
                                                John Smith                                            </p>
                                           
                                          </div>
                        
                        
                                      
                                   
                                     
                                        <div class="col-md-12 col-sm-12">
                                            <label class="label-text">Description </label>
                                            <p class="p-text">
                                                Are all procurement activities documented and approved according to company policies? 
                                            </p>
                                      
                                    
                                       
                                      </div>
                                   












                                    </div>


                                  </div>
                                </div>
                             
                              </div>
                            </div>
        </div>

        <div class="col-md-12">
            <h4>Conclusion</h4>
        </div>
<div class="widget-block p-4" style="background: #F4F9F5;" >
    <div class="row">
        <div class="col-md-6">
            <label class="label-text">Concluded by </label><p class="p-text">Ahamed Khan </p>

        </div>
        <div class="col-md-6">
            <label class="label-text">Concluded on </label><p class="p-text">16th Jan 2024</p>

            
        </div>

        <div class="col-md-12">
            <p class="mb-0">The findings outlined in this report highlight areas of improvement within the [Department/Process Name]. Immediate action is required for the Major NCs to mitigate risks, while other findings provide opportunities to enhance processes and ensure ongoing compliance with standards. A follow-up audit is recommended in [timeframe] to review the implementation of corrective actions. </p>
            <a class="a-link" href="#">Read more</a>
        </div>
    </div>
</div>

  </div>

 </div>


                </div>
              
            </div>

          

        </div>

    </div>
    <div class="col-md-3">
        <div class="widget-block bg-light-grey">

          
<div class="col-md-12">  <h4>Draft Info</h4></div>
            <div class="col-md-12">
                <label class="label-text">Updated by: </label>
                <div class="p-text">
                  <div class="user-widget d-flex mt-1 ">
                    <img src="assets/images/user-images/user-1.png" />
                    <span class="user-name ms-2">John Smith</span>
                  </div>                  </div>
               
              </div>
              <div class="col-md-12 mt-3">
                  <label class="label-text">Updated at: </label>
                  <p class="p-text">
                      April 14, 2024, 3:15 PM
                  </p>
            
          
             
            </div>
            <div class="col-md-12">
                <label class="label-text"> Comments: </label>
                <p class="p-text">
                    Initial Draft report submitted to HR, Finance and IT Departments for review
                </p>

                <a class="a-link" href="#">View History</a>
          
        
           
          </div>

          <div class="col-md-12">
            <hr class="grey-hr mt-3 mb-3">
          </div>

          <div class="col-md-12">
            <img class="me-2" src="assets/images/audit-icons/icon-down-caret.svg" /> Challenged findings
          </div>
          <div class="col-md-12 mt-3">
            <div class="widget-block">
                <div class="row">
                    <div class="col-md-12">
<p>Inadequate Control of QMS Documentation </p>

<a class="a-link">6 responses</a>

                    </div>

                </div>
                <div class="row mt-3">
                    <div class="col-md-12">
<p>Inadequate Control of QMS Documentation </p>

<a class="a-link">3 New responses</a>

                    </div>

                </div>

            </div>
          </div>



        </div>
    </div>
</div>

<!-- Draft Report End-->



<div class="row mt-4 mb-3">
    <div class="col-md-12 ">
        <label class="filter-plain  active me-2">Process (5)</label>   <label class="filter-plain  me-2">Control (4)</label>  <label class="filter-plain  me-2">Risk</label>  <label class="filter-plain  me-2">Incidents</label> <label class="filter-plain  me-2">Risk</label>  <label class="filter-plain  me-2">Compliance</label>  <label class="filter-plain  me-2">Task</label>  
     </div>
     </div>
<div class="row">
     <div class="col-md-8"><div class="widget-block widget-grey-bg">

        <div class="row">
            <div class="col-md-12">
              <div class="accordion theme-accordion" id="accordionExample">
                <div class="accordion-item check-list-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#risk1"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                    Evaluation of Procurement and Vendor Management Processes 
                    </button>

                 
                  </h2>
                  <a class="edit-btn-checklist"><i class="fa fa-pencil"></i> Edit </a>
                  <div
                    id="risk1"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                        <div class="row">
                            <div class="col-md-12">
                              <div class="accordion theme-accordion" id="accordionExample11">
                                <div class="accordion-item check-list-item">
                                  <h2 class="accordion-header" id="headingOne11">
                                    <button
                                      class="accordion-button"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#risk11"
                                      aria-expanded="true"
                                      aria-controls="collapseOne"
                                    >
                                    Are multi-factor authentication mechanisms implemented for critical systems? 
                                    </button>
                
                                 
                                  </h2>
                                  <div
                                    id="risk11"
                                    class="accordion-collapse collapse show"
                                    aria-labelledby="headingOne11"
                                    data-bs-parent="#accordionExample11"
                                  >
                                    <div class="accordion-body">
                                      <div class="row">
                                       
                        
                                        <div class="col-md-4 col-sm-6">
                                          <label class="label-text">Conformance: </label>
                                          <p class="p-text">
                                            <label class="status-success">Yes</label>
                                          </p>
                                         
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <label class="label-text">Comments: </label>
                                            <p class="p-text">
                                                Multi-factor authentication is in place for all critical systems, enhancing the security of access controls
                                            </p>
                                           
                                          </div>
                        
                        
                                      
                                   
                                        <div class="col-md-6 col-sm-6">
                                          <label class="label-text">Updated by: </label>
                                          <div class="p-text">
                                            <div class="user-widget d-flex mt-1 mb-0">
                                              <img src="assets/images/user-images/user-1.png" />
                                              <span class="user-name ms-2">John Smith</span>
                                            </div>                  </div>
                                         
                                        </div>
                                        <div class="col-md-6 col-sm-6">
                                            <label class="label-text">Updated at: </label>
                                            <p class="p-text">
                                                April 14, 2024, 3:15 PM
                                            </p>
                                      
                                    
                                       
                                      </div>
                                      <div class="col-md-6 col-sm-6">
                                        <label class="label-text">Evidence </label>
                                        <div class="uploaded-file justify-content-between align-items-center mb-2 mt-1">
                                            <div class="d-flex">
                                                <img src="assets/images/icons/icon-document-policiessvg" width="20" class="me-2">
                                                <div>
                                                COMP/2023/012.pdf001.pdf
                                                <br>
                                                <small >105KB</small></div>
                                            </div>
                                            
                            
                                            <div class="delet-icon cursor-pointer">
                                                <img src="assets/images/icons/icon-download-2.svg">
                                            </div>
                                        </div>
                                  
                                
                                   
                                  </div>

                                  <div class="col-md-12">
                                    <button  type="button" class="btn btn-primary btn-theme float-end"> Update Answer</button>
                                  </div>

<div class="col-md-12">
    <hr class="grey-hr">
</div>

<div class="col-md-12">
    <h4 class="font-lg">Findings <a class="btn-add-more-plus">Add finding</a>   </h4>
</div>

<div class="col-md-12">
    <div class="widget-block widget-grey-bg text-center d-flex align-items-center justify-content-center ">

        <div>

        <h4 class="text-center">No Findings Yet</h4>
        <p>Once audit execution begins, any identified findings will<br> appear here.</p>
        <button  type="button" class="btn btn-primary btn-theme">Add finding</button>

    </div>

    </div>
</div>

<div class="accordion theme-accordion" id="accordionExample11q">
    <div class="accordion-item check-list-item">
      <h2 class="accordion-header " id="headingOne11">
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#risk11q"
          aria-expanded="true"
          aria-controls="collapseOne"
        >
        Was the data breach incident reported promptly according to the established protocols?
        </button>

     
      </h2>
      <div
        id="risk11q"
        class="accordion-collapse collapse "
        aria-labelledby="headingOne11"
        data-bs-parent="#accordionExample11"
      >
        <div class="accordion-body">
          <div class="row">
           

            <div class="col-md-4 col-sm-6">
              <label class="label-text">Conformance: </label>
              <p class="p-text">
                <label class="status-success">Yes</label>
              </p>
             
            </div>
            <div class="col-md-8 col-sm-12">
                <label class="label-text">Comments: </label>
                <p class="p-text">
                    Multi-factor authentication is in place for all critical systems, enhancing the security of access controls
                </p>
               
              </div>


          
       
            <div class="col-md-6 col-sm-6">
              <label class="label-text">Updated by: </label>
              <div class="p-text">
                <div class="user-widget d-flex mt-1 mb-0">
                  <img src="assets/images/user-images/user-1.png" />
                  <span class="user-name ms-2">John Smith</span>
                </div>                  </div>
             
            </div>
            <div class="col-md-6 col-sm-6">
                <label class="label-text">Updated at: </label>
                <p class="p-text">
                    April 14, 2024, 3:15 PM
                </p>
          
        
           
          </div>
          <div class="col-md-6 col-sm-6">
            <label class="label-text">Evidence </label>
            <div class="uploaded-file justify-content-between align-items-center mb-2 mt-1">
                <div class="d-flex">
                    <img src="assets/images/icons/icon-document-policiessvg" width="20" class="me-2">
                    <div>
                    COMP/2023/012.pdf001.pdf
                    <br>
                    <small >105KB</small></div>
                </div>
                

                <div class="delet-icon cursor-pointer">
                    <img src="assets/images/icons/icon-download-2.svg">
                </div>
            </div>
      
    
       
      </div>

      <div class="col-md-12">
        <button  type="button" class="btn btn-primary btn-theme float-end"> Update Answer</button>
      </div>

<div class="col-md-12">
<hr class="grey-hr">
</div>

<div class="col-md-12">
<h4 class="font-lg">Findings <a class="btn-add-more-plus">Add finding</a>   </h4>
</div>

<div class="col-md-12">
<div class="widget-block widget-grey-bg text-center d-flex align-items-center justify-content-center ">

<div>

<h4 class="text-center">No Findings Yet</h4>
<p>Once audit execution begins, any identified findings will<br> appear here.</p>
<button  type="button" class="btn btn-primary btn-theme">Add finding</button>

</div>

</div>
</div>




        </div>


      </div>
    </div>
 
  </div>
</div>




                                    </div>


                                  </div>
                                </div>
                             
                              </div>
                            </div>
                          </div>
                
                    </div>
                    </div>
                    </div>
                    
                
              
              
               
              </div>
              <div class="accordion-item check-list-item">
                <h2 class="accordion-header" id="headingOneee">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#risk1ee"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                  Evaluation of Procurement and Vendor Management Processes 
                  </button>

               
                </h2>
                <a class="edit-btn-checklist"><i class="fa fa-pencil"></i> Edit </a>
                <div
                  id="risk1ee"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                  
                  </div>
                  </div>
                  
              
            
            
             
            </div>
              </div>
              
            </div>
             <div class="col-md-12">
                    <a class="btn-add-more-plus">Add New process</a> 
                </div> 
          </div>


    </div></div>

    <div class="col-md-4">

        <div class="widget-block widget-grey-bg">
            <div class="row">
                <div class="col-md-12">
                    <h4>Process Checklist Summary</h4>

                </div>

                <div class="col-md-12">
                    <img src="assets/images/chart/checklist-chart1.svg" class="img-fluid d-block mx-auto">
                </div>
            </div>
        




        </div>

        <div class="widget-block widget-grey-bg">
            <div class="row">
                <div class="col-md-12">
                    <h4>Audit Findings Summary</h4>

                </div>

                <div class="col-md-12">
                    <img src="assets/images/chart/checklist-chart2.svg" class="img-fluid d-block mx-auto">
                </div>
            </div>
        




        </div>

    </div>
    

</div>


