import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { makeObservable } from 'mobx';
import { SideMenuItem, SideMenuSubItem } from '../modals/side-menu.modal';

@Injectable({
  providedIn: 'root',
})
export class SideMenuStore {
  menuItems: SideMenuItem[] = [];

  constructor(route: Router) {
    makeObservable(this);
    this.loadInitialMenu();
    this.updateMenuActiveState(route.url);
  }

  loadInitialMenu() {
    this.menuItems = [
      {
        module: 'dashboard',
        icon: 'icon-dashboard.svg',
        label: 'Dashboards',
        baseRoute: '/dashboard',
        isCollapse: false,
        isActive: false,
        children: [
          {
            label: 'GRC Dashboard',
            route: '/dashboard/my-dashboard',
            isActive: false,
          },
          // {
          //   label: 'Task Dashboard',
          //   route: '/dashboard/my-dashboard',
          //   isActive: false,
          // },
        ],
      },
      {
        module: 'organization',
        icon: 'icon-organization.svg',
        label: 'side_menu.organization.organization',
        baseRoute: '/organization',
        isActive: false,
        isCollapse: false,
        children: [
          {
            label: 'side_menu.organization.strategic_profile',
            route: '/organization/strategic-profile',
            isActive: false,
          },
          {
            label: 'side_menu.organization.user_management',
            route: '/organization/users',
            isActive: false,
          },
          {
            label: 'side_menu.organization.policies',
            route: '/organization/policies',
            isActive: false,
          },
          {
            label: 'side_menu.organization.process',
            route: '/organization/process',
            isActive: false,
          },
         
        ],
      },
      {
        module: 'compliance',
        icon: 'icon-compliance.svg',
        label: 'side_menu.compliance.compliance',
        baseRoute: '/compliance',
        isActive: false,
        isCollapse: false,
        children: [
          {
            label: 'side_menu.compliance.compliance_register',
            route: '/compliance/register',
            isActive: false,
          },
          {
            label: 'side_menu.compliance.sla&contrats',
            route: '/compliance/sla-contracts',
            isActive: false,
          },
          {
            label: 'side_menu.organization.controls',
            route: '/compliance/controls',
            isActive: false,
          },
        ],
      },
      {
        module: 'risk',
        icon: 'icon-risk.svg',
        label: 'side_menu.risk.risk',
        baseRoute: '/risk',
        isActive: false,
        isCollapse: false,
        children: [
          {
            label: 'side_menu.risk.risk_matrix',
            route: '/risk/risk-matrix',
            isActive: false,
          },
          {
            label: 'side_menu.risk.risk_appetite_statement',
            route: '/risk/risk-appetite-statement',
            isActive: false,
          },
          {
            label: 'side_menu.risk.potential_risk',
            route: '/risk/potential-risk',
            isActive: false,
          },
          {
            label: 'side_menu.risk.risk_kri',
            route: '/risk/kri-register',
            isActive: false,
          },
          {
            label: 'side_menu.risk.risk_register',
            route: '/risk/risk-register',
            isActive: false,
          },
          {
            label: 'side_menu.risk.risk_mitigation',
            route: '/risk/risk-mitigation',
            isActive: false,
          },
          {
            label: 'side_menu.risk.risk_heat_map',
            route: '/risk/risk-heatmaps',
            isActive: false,
          },
        ],
      },
      {
        module: 'audit',
        icon: 'icon-risk.svg',
        label: 'Audit',
        baseRoute: '/audit',
        isActive: false,
        isCollapse: false,
        children: [
          {
            label: 'Audit Universe',
            route: '/audit/audit-universe',
            isActive: false,
          },
          {
            label: 'Audit Plans',
            route: '/audit/audit-plans',
            isActive: false,
          },
          {
            label: 'Audits',
            route: '/audit/audits',
            isActive: false,
          },
          {
            label: 'Audit Findings',
            route: '/audit/audit-findings',
            isActive: false,
          },
        ],
      },
      {
        module: 'document',
        icon: 'icon-document.svg',
        label: 'side_menu.document.document',
        isCollapse: false,
        isActive: false,
        baseRoute: '/document',
        children: [
          {
            label: 'side_menu.document.document_register',
            route: '/document/register',
            isActive: false,
          },
          {
            label: 'side_menu.document.change_request',
            route: '/document/change-request',
            isActive: false,
          },
        ],
      },
      {
        module: 'bcm',
        icon: 'icon-incident.svg',
        label: 'Business Continuity',
        isActive: false,
        isCollapse: false,
        baseRoute: '/business-continuity',
        children: [
          {
            label: 'Policies',
            route: '/business-continuity/policies',
            isActive: false,
          },
          {
            label: 'Functions',
            route: '/business-continuity/functions',
            isActive: false,
          },
          {
            label: 'BCP',
            route: '/business-continuity/bcp',
            isActive: false,
          },
         
        ],
      },
      {
        module: 'incident',
        icon: 'icon-incident.svg',
        label: 'side_menu.incident.incident',
        isActive: false,
        isCollapse: false,
        baseRoute: '/incident',
        children: [
          {
            label: 'side_menu.incident.incident_register',
            route: '/incident/register',
            isActive: false,
          },
          {
            label: 'side_menu.incident.action_plans',
            route: '/incident/action-plan',
            isActive: false,
          },
        ],
      },
      {
        module: 'review_meeting',
        icon: 'icon-incident.svg',
        label: 'side_menu.review_meeting.review_meeting',
        baseRoute: '/review-meeting',
        isActive: false,
        isCollapse: false,
        children: [
          {
            label: 'side_menu.review_meeting.meeting',
            route: '/review-meeting/meetings',
            isActive: false,
          },
          {
            label: 'side_menu.review_meeting.meeting_action_plans',
            route: '/review-meeting/action-plan-list',
            isActive: false,
          },
        ],
      },
      {
        module: 'audit_insights',
        icon: 'icon-risk.svg',
        label: 'Audit Insights',
        baseRoute: '/audit-insights',
        isActive: false,
        isCollapse: false,
        children: [
          {
            label: 'Audit Plans',
            route: '/audit-insight/audit-plans',
            isActive: false,
          },
          {
            label: 'Audits',
            route: '/audit-insight/audits',
            isActive: false,
          },
          {
            label: 'Document Requests',
            route: '/audit-insight/document-requests',
            isActive: false,
          },
          {
            label: 'Draft Reports',
            route: '/audit-insight/draft-reports',
            isActive: false,
          },
          {
            label: 'Findings',
            route: '/audit-insight/findings',
            isActive: false,
          },
          {
            label: 'Action Plans',
            route: '/audit-insight/action-plans',
            isActive: false,
          },
        ],
      },
      {
        icon: 'icon-management-review.svg',
        label: 'Reports',
        route: '/management-review',
        baseRoute: '/report',
        isActive: false,
        isCollapse: false,
      },
      {
        module: 'settings',
        icon: 'icon-settings.svg',
        label: 'side_menu.settings',
        route: 'settings/access-control',
        class: 'setting-menu',
        baseRoute: '/settings',
        isActive: false,
        isCollapse: false,
      },
    ];
  }

  activateMainMenu(selectedItem: SideMenuItem): void {
    this.menuItems.forEach((item) => {
      if (item.module === selectedItem.module) {
        item.isCollapse = !item.isCollapse;
        item.isActive = true;
      } else {
        item.isActive = false;
        item.isCollapse = false;
      }
    });
  }

  activateSubMenu(selectedItem: SideMenuSubItem): void {
    this.menuItems.forEach((mainItem) => {
      mainItem.children?.forEach((subItem) => {
        if (subItem === selectedItem) {
          subItem.isActive = true;
          mainItem.isActive = true;
        } else {
          subItem.isActive = false;
        }
      });
    });
  }

  updateMenuActiveState(currentRoute: string): void {
    this.menuItems.forEach((mainItem) => {
      mainItem.children?.forEach((subItem) => {
        if (subItem.route === currentRoute) {
          subItem.isActive = true;
          mainItem.isCollapse = true;
          mainItem.isActive = true;
        } else {
          subItem.isActive = false;
        }
      });
    });
  }
}
