import { Routes } from '@angular/router';
import { bcmPolicyRoutes } from './policy/bcm-policy-route';
import { businessFunctionRoutes } from './business-functions/business-functions.routes';
import { businessContinuityPlanRoutes } from './bcp/bcm-business-continuity-plan.routes';

export const businessContinuityRoutes: Routes = [
  {
    path: 'policies',
    children: [...bcmPolicyRoutes],
  },
  {
    path: 'functions',
    children: [...businessFunctionRoutes],
  },
  {
    path: 'bcp',
    children: [...businessContinuityPlanRoutes],
  },
  
];
